import * as React from 'react';

import { PhaseInfoCard } from '@/components/phases';
import { Carousel, Fats, Fruits, Grains, Protein, Science, Veggies } from '@/components/ui';

import { RouterOutput } from '../../../trpc/router';

// Currently not being used in favor of <InsightsNutritionCta />

type DashboardNutrutionProps = {
  phaseday: RouterOutput['users']['workout']['phaseday'];
};

export const DashboardNutrution = React.memo<DashboardNutrutionProps>(
  ({
    phaseday: {
      nutritionFats,
      nutritionFatsList,
      nutritionFruits,
      nutritionFruitsList,
      nutritionGoal,
      nutritionGrains,
      nutritionGrainsList,
      nutritionImage,
      nutritionProteins,
      nutritionProteinsList,
      nutritionScience,
      nutritionVeggies,
      nutritionVeggiesList,
    },
  }) => {
    return (
      <section>
        <header className='max-w-7xl px-6 md:px-12 mt-6 md:mt-8 mx-auto'>
          <h2 className='mb-4 font-josefin text-xs font-bold tracking-widest uppercase text-transparent bg-clip-text bg-gradient-yellow-to-purple'>
            Today&apos;s Nutrition
          </h2>
        </header>
        <Carousel itemGap={8}>
          <PhaseInfoCard
            image={`/${nutritionImage.fileName}`}
            kicker='Your Goal'
            title={nutritionGoal}
            variant='cover'
          />
          <PhaseInfoCard copy={nutritionScience} icon={<Science />} title='Science' />
          <PhaseInfoCard
            copy={nutritionProteins}
            icon={<Protein />}
            list={nutritionProteinsList.split(',')}
            title='Protein'
          />
          <PhaseInfoCard
            copy={nutritionVeggies}
            icon={<Veggies />}
            list={nutritionVeggiesList.split(',')}
            title='Veggies'
          />
          <PhaseInfoCard copy={nutritionFats} icon={<Fats />} list={nutritionFatsList.split(',')} title='Fats' />
          <PhaseInfoCard
            copy={nutritionGrains}
            icon={<Grains />}
            list={nutritionGrainsList.split(',')}
            title='Grains'
          />
          <PhaseInfoCard
            copy={nutritionFruits}
            icon={<Fruits />}
            list={nutritionFruitsList.split(',')}
            title='Fruits'
          />
        </Carousel>
      </section>
    );
  },
);

DashboardNutrution.displayName = 'DashboardNutrution';
